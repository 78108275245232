@import url(fontiran.css); /* لینک فایلی که وظیفه بارگذاری فونت ها را برعهده دارد */
@import url(fontiran_iransans.css); /* لینک فایلی که وظیفه بارگذاری فونت ها را برعهده دارد */

* {
	font-family: IRANSans, iranyekan  !important;
}

.segoe-font {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
/* h1, h2, h3, h4, h5, h6,input, textarea, p, div, span{
	font-family: IRANSans, iranyekan !important;
} */
h1 {
	font-weight: bold;
}
.wrapper {
	max-width: 900px;
	margin: 0 auto;
}
.ltr {
	direction: ltr;
}
.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-small {
	font-size: 0.8em;
}
.text-xsmall {
	font-size: 0.6em;
}
.text-large {
	font-size: 1.2em;
}
.text-xlarge {
	font-size: 1.4em;
}
.text-underline {
	text-decoration:underline;
}
.text-thin {
	font-weight: 100;
}
.text-light {
	font-weight: 300;
}
.text-regular {
	font-weight: normal;
}
.text-medium {
	font-weight: 500;
}
.text-bold {
	font-weight: bold;
}
.text-extrabold {
	font-weight: 800;
}
.text-black {
	font-weight: 900;
}
.text-extrablack {
	font-weight: 950;
}
blockquote {
	font-weight: 500;
	padding: 10px;
	border: 1px dashed #666666;
}

.mainbox {
	width: 100%;
	background-color: #EFEFEF;
	display: table;
	margin-bottom: 30px;
	border-right: 8px solid #CCFF33;
}

.mainboxnegativ {
	width: 100%;
	background-color: #000000;
	display: table;
	margin-bottom: 30px;
	border-right: 8px solid #CCFF33;
	color: #F9F9F9;
}

.mainbox2 {
	font-size: 1em;
	width: 90%;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.mainbox3 {
	width: 100%;
	background-color: #DFDFDF;
	display: table;
	margin-bottom: 30px;
	border-right: 8px solid #bd70ff;
}

.mainbox2negativ {
	font-size: 1em;
	color: #F9F9F9;
	background-color: #000000;
	padding-right: 20px;
}


.farsiparagraph {
	font-size: 1em;
	width: 47%;
	float:right;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;

}

.farsiparagraph_negativ {
	font-size: 1em;
	color: #F9F9F9;
	background-color: #000000;
	width: 47%;
	float:right;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;

}


.englishparagraph {
	font-size: 1em;
	width: 47%;
	float: left;
	direction:ltr;
	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;


}

.englishparagraph_negativ {
	font-size: 1em;
	color: #F9F9F9;
	background-color: #000000;
	width: 47%;
	float: left;
	direction:ltr;
	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;


}
.rightbox {
	width: 60%;
	padding-right: 20px;
	padding-left: 5px;
	float: right;
	margin-left: 10px;
	margin-bottom: 0px;
	min-width: 0px;
	background-color: #F7F7F7;

}

.titelbox {
	width: 60%;
	padding-right: 25px;
	padding-left: 0px;
	float: right;
	margin-left: 10px;
	margin-bottom: 0px;
	min-width: 0px;
	background-color: #d5d5d5;
	color: #4B4B4B;
}


.lefttbox {
	
	padding-right: 20px;
	padding-left: 4px;
	float: right;
	margin-bottom: 10px;
	min-width: 0px;
}

.alphabet {
	width: 35%;
	float: left;
	font-size: 21em;
	text-align: center;
	font-weight: 500;
	color: #999999;
}

.alphabet2 {
	width: 35%;
	float: left;
	direction: ltr;
	font-size: 1.6em;
	text-align: left;
	font-weight: 500;
	color: #333333;
	margin-top: 100px;
}
.footer {
	font-weight: 300;
	font-size: 0.7em;
	text-align: center;
	direction: ltr;
	margin-bottom: 0px;
	padding-bottom: 0px;
}
